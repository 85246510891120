@import "../../../variables";

.addContractForm{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  height: 65px;
  align-items: end;
  background-color: #fff;
  filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));
  padding: 5px 12px 5px;

  .buttonContainer{
    display: flex;
    justify-content: space-between;

    .buttonForm{
      display: inline-block;
      flex: 1 1 95px;
      align-self: stretch;
      font-family: "open_sans_sb";
      font-variant: small-caps;
      font-size: $fontSizeMain;
      background-color: #e6e6e6;
      filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));
      padding: 5px 10px;
      border: none;
      color: $colorFontMain;
      cursor: pointer;
      margin-bottom: 15px;

      &:last-child{
        margin-left: 20px;
      }
    }
  }
}

.spinner {
  width: 100px;
  // margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.errorMsg{
  grid-column: 1/3;
  color: red;
}




// =============================================== Media queries ===============================================


@media only screen and (max-width: 768px) {
  .addContractForm{
    grid-row-gap: 5px;
    grid-template-columns: 1fr 1fr;
    height: auto;
    padding: 20px 12px 5px;

    .buttonContainer{
      grid-column: 1/3;
    }
  }
}

@media only screen and (max-width: 708px) {
  .addContractForm{
    // grid-row-gap: 15px;
    // grid-template-columns: 1fr 1fr;
    height: auto;

    .buttonContainer{
      // grid-column: 1/3;
    }
  }
}

@media only screen and (max-width: 500px) {
  .addContractForm{
    grid-row-gap: 15px;
    grid-template-columns: 1fr;
    height: auto;

    .buttonContainer{
      grid-column: 1/2;
    }
  }
}
