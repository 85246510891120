@import "../../variables";

.container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url(../../images/fone3.jpg) center center no-repeat; 
  background-size: cover;
  padding: 60px 0;
  color: $colorFontLight;

  .contacts{
    margin-right: 20px;
    text-align: center;
    font-size: $fontSizeMain + 2px;
    font-family: "open_sans2";
    
    .title{
      font-family: "open_sans_sb";
      font-size: $fontSizeMain + 4px;
      font-variant: small-caps;
    }

    .subTitle{
      font-variant: small-caps;
      margin-bottom: 10px;
    }

    .telephones{
      // margin-top: 10px;
      // margin-bottom: 30px;
    }

    .line{
      display: inline-block;
      width: 85px;
      height: 2px;
      background-color: $colorBorderLight;
      margin: 20px 0 10px;
    }

    .schedule{
      font-size: $fontSizeMain - 2px;
      display: flex;
      justify-content: space-between;
      text-align: start;
      line-height: 25px;

      .short{
        display: none;
      }


      div:last-child{
        text-align: center;
        margin-left: 20px;
      }
    }

    .email{
      font-size: $fontSizeMain + 1px;

      a{
        color: inherit;
        text-decoration: inherit;
      }
    }
  }

  .form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 420px;
    border: #2e5471 1px solid;
    padding: 20px;

    .titleForm{
      text-align: center;
      font-size: $fontSizeMain + 12px;
      font-weight: bold;
      margin-bottom: 30px;
      font-family: "open_sans_sb";
      font-variant: small-caps;
    }

    .buttonForm{
      display: inline-block;
      flex: 0 0 40px;
      width: 290px;
      margin-top: 10px;
      background: rgba(133, 195, 233, 0.5);      
      border: none;
      // box-shadow: 0 2px 2px 1px #cdcdcd;
      font-family: "open_sans2";
      font-weight: bold;
      font-size: $fontSizeMain + 1px;
      color: $colorFontLight;
    }

    .buttonLoginForm{
      margin-top: 30px;
    }

    .buttonContainer{
      width: 290px;
      font-size: $fontSizeMain - 1px;

      .link{
        margin-top: 40px;
        cursor: pointer;
        font-size: $fontSizeMain + 2px;
        float: left;    
    
        &:hover{
          font-weight: bold;
        }
        
        a{
          text-decoration: none;
          font-size: $fontSizeMain - 2px;
          color: $colorFontLight;

          &:visited{
            // color: #feffff;
          }
        }
      }

      .link:last-child{
        float: right;
      }
    }

    .buttonBack{
      cursor: pointer;
      margin-top: 20px;
      
      &:hover{
        font-weight: bold;
      }
    }

    .navlinkBack{
      margin-top: 20px;

      a{
        text-decoration: none;
        color: $colorFontLight;
        
        &:hover{
          font-weight: bold;
        }        
      }
    }

    .warning{
      font-family: "open_sans2";
      font-variant: small-caps;
      font-size: $fontSizeMain + 4px;
      text-align: center;
      margin-bottom: 24px;
    }

    .linkResetWindow{
      margin-top: 40px;
      cursor: pointer;
      font-family: "open_sans2";
      font-variant: small-caps;
      font-size: $fontSizeMain + 2px;
      a{
        
        color: #feffff;
      }

      &:hover{
        font-weight: bold;
      }
      
      a{
        // text-decoration: none;
        // font-size: 18px;
        // color: #feffff;

        &:visited{
          // color: #feffff;
        }
      }
    }
  }    
}

.spinner{
  width: 150px;
  margin: 0 auto;
}

// .msgError{
//   text-align: center;
//   color: red;
// }

// .msgSuccess{
//   text-align: center;
//   // color: #fff;
// }

// =============================================== Media queries ===============================================

@media only screen and (max-width : 760px)  {

  .container{
    .contacts{
      .schedule{
        .long{
          display: none;
        }
        .short{
          display:inline;
        }
      }
    }

  }  
}

@media only screen and (max-width : 660px)  {

  .container{
    flex-direction: column;
    .contacts{
      order: 2;
      .title{
        margin-top: 60px;
      }
      .schedule{
        .long{
          display: inline;
        }
        .short{
          display:none;
        }
      }
    }

    .form{
      order: 0;
      padding: 20px 0;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }  
}

@media only screen and (max-width : 533px)  {

  .container{

    .form{
      width: 300px;
      height: auto;

      .buttonForm{
        width: 250px;
      }

      .buttonContainer{
        width: 250px;
      }
    }
  }  
}

@media only screen and (max-width : 360px)  {

  .container{

    .form{
      // width: 300px;
      // height: auto;
      // border: none;
      // border-top: none;
      // border-left: none;
      // border-right: none;
    }
  }  
}

