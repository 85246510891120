@import './variables';

.foneBlue{
  background-color: $phoneColor;
}


.foneWhite{
  background-color: white;
}

.container {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 100vh;
}

// =============================================== Media queries ===============================================

@media only screen and (max-width: 1281px) {
  .container {
    width: 85%;
  }
}

@media only screen and (max-width : 1024px) {
  .container{
    width: 90%;
  }
}

@media only screen and (max-width : 1024px) {
  .container{
    width: 95%;
  }
}
