.noData{
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  color: gray;
  text-align: center;
  align-self: center;
  width: 100%;
  // span{
  //   font-size: 54px;
  //   font-style: normal;
  // }
}
