@import "../../variables";

.formControl{

   .fieldContainer{
      border-bottom: 1px solid #84c2e9;
      position: relative;

      ::-webkit-input-placeholder { /* WebKit browsers */
         font-size: $fontSizeMain - 2px;
         color: $secondaryFontColor;
         opacity: 1;
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
         color: $secondaryFontColor;
         font-size: $fontSizeMain - 2px;
         opacity: 1;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
         color: $secondaryFontColor;
         font-size: $fontSizeMain - 2px;
         opacity: 1;
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
         color: $secondaryFontColor;
         font-size: $fontSizeMain - 2px;
         opacity: 1;
      }

      :focus::-webkit-input-placeholder { /* WebKit browsers */
         opacity: 0;
       }
       :focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
         opacity: 0;
       }
       :focus::-moz-placeholder { /* Mozilla Firefox 19+ */
         opacity: 0;
       }
       :focus:-ms-input-placeholder { /* Internet Explorer 10+ */
         opacity: 0;
       }

      .tip{
        display: block;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: 3px;
        left: 6px;
        font-size: $fontSizeMain - 2px;
        color: $secondaryFontColor;
        transition: top 0.5s, left 0.5s, visibility 0.5s, opacity 0.5s;
      }

      input{
         width: 100%;
         outline: none;
         border: none;
         font-family: "open_sans2";
         background: none;

         &:focus + .tip{
            display: block;
            position: absolute;
            top: -15px;
            left: 0;
            font-size: 12px;
            color: $colorFontMain;
            visibility: visible;
            opacity: 1;
            // display: none
         }
      }
   }

   .fieldContainerCurrentValue{
      text-align: center;
      // ::-webkit-input-placeholder { /* WebKit browsers */
      //    font-size: $fontSizeMain - 2px;
      //    color: $colorFontMain;
      // }
      // :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      //    color: $colorFontMain;
      //    font-size: $fontSizeMain - 2px;
      // }
      // ::-moz-placeholder { /* Mozilla Firefox 19+ */
      //    color: $colorFontMain;
      //    font-size: $fontSizeMain - 2px;
      // }
      // :-ms-input-placeholder { /* Internet Explorer 10+ */
      //    color: $colorFontMain;
      //    font-size: $fontSizeMain - 2px;
      // }      

      input{
         // width: 184px;
         outline: none;
         font-family: "open_sans2";
         appearance: textfield;
      }
   }

   .isError{
      color: $secondaryFontColor;
      font-size: 10px;
      font-family: "open_sans2";
      font-variant: normal;
      text-align: center;
   }

   .notError{
      color: darkred;
      font-size: 10px;
      font-family: "open_sans2";
      font-variant: normal;
      // font-size: 12px;
      visibility: hidden;
      height: 15px;
   }

   .checkboxContainer{
      border-bottom: 1px solid #84c2e9;
      span{
         font-size: 12px;
      }
      input{

      }
   }
}

.formControl.error{
   .fieldContainerCurrentValue{
      text-align: center;

      input{
         height: 30px;
         // width: 184px;
         padding: 0 7px;
         border: 2px solid red;
         border-radius: 5px;
      }
   }

   .fieldContainer {
      border-bottom: 1px solid red;
   }

   .checkboxContainer{
      border-bottom: 1px solid red;  
   }
}


// =============================================== Media queries ===============================================


@media only screen and (max-width : 420px)  {
   .formControl{
      // width: 190px;
   
      .fieldContainer{
         input{
         }
      }
   
      .fieldContainerCurrentValue{   
         input{
         }
      }
   
      .isError{
      }
   
      .notError{
      }
   
      .checkboxContainer{
      }
   }
   
   .formControl.error{
      .fieldContainerCurrentValue{
         text-align: center;
         input{
         }
      }
   
      .fieldContainer {
         border-bottom: 1px solid red;
      }
   
      .checkboxContainer{
         border-bottom: 1px solid red;  
      }
   }
}
