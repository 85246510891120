@import '../../variables';

// .overlay {
//   background-color: rgba(8, 53, 86, 0.60);

//   .content {
//     top: 50%;
//     left: 50%;
//     right: auto;
//     bottom: auto;
//     margin-right: -50%;
//     transform: translate(-50%, -50%);
//     height: 300px;
//     max-width: 250px;
//     min-width: 200px;
//     padding: 20px;
    // border: '1px solid #033c5f'

    .modal{
      font-family: "open_sans2";
      font-size: $fontSizeMain;
      color: $colorFontMain;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $colorBorderLight;
      padding: 20px;
      box-sizing: border-box;
      font-variant: small-caps;
      min-height: 300px;

      .title{
        font-size: $fontSizeMain + 8px;
        text-align: center;
      }

      .line{
        height: 1px;
        width: 80%;
        background-color: #85c3e9;
        margin: 20px auto;
      }

      .message{
        display: inline-block;
        text-align: center;


      }
        
      button{
        margin-top: 20px;
        font-variant: small-caps;
        color: $colorFontMain;
        background-color: $buttonColor;
        box-shadow: 0px 3px 7px 0px rgba(139,139,139,0.51);
        padding: 5px 28px;
        border: none;    
      }

      .sendButton{
        margin-top: 20px;
        cursor: pointer;
        text-align: center;
        font-size: $fontSizeMain - 2px;
        text-decoration: underline;
      }
    }

//   }
// }






