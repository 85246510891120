@import '../../../../../variables';


  .oneYearTable {
    border-collapse: collapse;
    font-size: $fontSizeMain - 2px;
    height: 0;
    font-family: "open_sans2";
    color: $colorFontMain;
    font-variant: normal;
    filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));
    
    td, th {
      border: 1px solid rgb(200,200,200);  
    }

    th {
      background-color: #005fa1;
      color: #feffff;
      padding: 2px 8px;
      font-size: $fontSizeMain - 3px;
    }

    .tableHead{
      padding: 2px 30px;
    }

    .tdMonth {
      text-align: start;
    }

    td {
      padding: 0 8px;
      text-align: end;
      line-height: 1.1;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    tr:nth-child(odd) td {
      background-color: rgb(250,250,250);
    }

    tr:nth-child(even) td {
      background-color: rgb(235,235,235);
    }

    .row{
      padding-left: 30px;
    }
  }





// =============================================== Media queries ===============================================

@media only screen and (max-width : 1366px) {
  .oneYearTable {
    th {
      padding: 2px 5px;
    }
  }
}

@media only screen and (max-width: 486px) {
  .oneYearTable {
    th {
      font-size: $fontSizeMain - 4px;
    }
  }
}
