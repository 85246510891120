@import '../../variables';

.formControl{
  width: 290px;
  display: inline-block;   
  margin-bottom: 10px;
  color: #ffffff;

  .fieldContainer{
		width: 290px; 
		border-bottom: 1px solid #ffffff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

    // ::placeholder{
    //   color: red;
    // }

		// ::-webkit-input-placeholder { /* WebKit browsers */
		// 		color: grey;
		// }
		// :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		// 		color:grey;
		// }
		// ::-moz-placeholder { /* Mozilla Firefox 19+ */
		// 		color:grey;
		// }
		// :-ms-input-placeholder { /* Internet Explorer 10+ */
		// 		color:grey;
		// }

		// :focus::-webkit-input-placeholder { /* WebKit browsers */
		// 	opacity: 0;
		// }
		// :focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		// 	opacity: 0;
		// }
		// :focus::-moz-placeholder { /* Mozilla Firefox 19+ */
		// 	opacity: 0;
		// }
		// :focus:-ms-input-placeholder { /* Internet Explorer 10+ */
		// 	opacity: 0;
		// }			

		//    .tip{
		//       display: none; /*скрываем подсказку*/
		// }

		.tip{
			display: block;
			position: absolute;
			opacity: 0;
			visibility: hidden;
			top: 3px;
			left: 6px;
			font-size: 18px;
			color: grey;
			transition: top 0.5s, left 0.5s, visibility 0.5s, opacity 0.5s, font-size 0.5s;
		}
      
		input{
			outline: none;
			border: none;
			width: 255px;
			font-family: "open_sans2";
			font-size: 18px;
			color: #ffffff;
			background: none;
      

      &::placeholder{
        color: gray;
        opacity: 1;
      }

      &:focus::placeholder{
        opacity: 0;
        transition: opacity .5s;
      }

			&:focus + .tip{
				display: block;
				position: absolute;
				top: -15px;
				left: 0;
				font-size: 12px;
				visibility: visible;
				opacity: 1;
				color: #ffffff;
			}

      &:-webkit-autofill {
        box-shadow: inset 0 0 0 50px $phoneColor;        
        -webkit-text-fill-color: white;
      }

      &:autofill {
        box-shadow: none;        
        // -webkit-text-fill-color: white;
      }
		}

      .hidePass{
         height: 16px;
      }

      .fieldIcon{
         // object-fit: none;
         height: 16px;
      }
   }

   .isError{
      color: #d9d9d9;
      font-size: 12px;
   }

   .notError{
      color: darkred;
      font-size: 12px;
      visibility: hidden;
      height: 15px;
   }

   .checkboxContainer{
      // width: 290px; 
      // border-bottom: 1px solid #84c2e9;
      span{
         font-size: 12px;
      }
   }
}

.formControl.error {
   width: 290px;
   .fieldContainer {
      border-bottom: 1px solid red;
      // width: 290px;
   }

   .checkboxContainer{
      // width: 290px; 
      border-bottom: 1px solid red;  
   }
}


// =============================================== Media queries ===============================================
@media only screen and (max-width : 533px)  {
   .formControl{
      width: 250px;

      .fieldContainer {
         width: 250px;
         input{
            width: 215px;
         }
      }

      .checkboxContainer{
         // width: 290px; 
         // border-bottom: 1px solid #84c2e9;
         span{
            font-size: 11px;
         }
      }
   }

   .formControl.error {
      width: 250px;
   }
}

