@import '../../../../../variables';

// .title{
//   margin-bottom: 0;
//   font-family: "open_sans2";
//   font-size: $fontSizeMain + 2;
//   color: $colorFontMain;
//   font-weight: bold;
// }

// .line{
//   height: 0;
//   width: 200px;
//   background-color: black;
//   margin: 0 auto 10px ;
// }

.title2{
  display: flex;
  justify-content: space-between;
  text-align: start;
  font-weight: bold;

  div{
    display: flex;
    align-items: flex-end;
  }

  .serviceName{
    margin-right: 20px;
    font-size: $fontSizeMain + 2px;
    color: red;
  }

  .yearList{
    div {
      margin-right: 20px;
    }
    div:last-child {
      margin-right: 0;
    }
  }

  .activeYear, .passiveYear{
    cursor: pointer;
    font-weight: normal;
    color: #a5a5a5;
  }

  .activeYear{
    color: $colorFontMain;
    font-size: $fontSizeMain + 4px;
    font-weight: bold;
  }

  .passiveYear{
    font-size: $fontSizeMain;
  }
}

// =============================================== Media queries ===============================================
@media only screen and (max-width: 486px) {
  .title2{
    .serviceName{
      font-size: $fontSizeMain;
    }
  }
}