@import "../../../variables";

.contractContainer{
  border: 1px solid $colorBorderSecondary;
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: -24px 0 0 -12px $colorBorderMain;
  filter: drop-shadow(0px 3px 3.5px rgba(139,139,139,0.52));
  color: $colorFontMain;
  font-size: $fontSizeMain;
  font-variant: small-caps;
  font-family: "open_sans_sb";
  
  .title_contract{
    text-align: center;
    margin-bottom: 10px;  
    border-bottom: 1px solid $colorBorderMain;
    margin: 12px;
    padding-bottom: 12px;

    span{
      white-space: nowrap;
    }
  }

  .burgerButton{
    // position: absolute;
    background-color: $buttonColor;
    display: none;
    transform-origin: 16px 11px;
    outline: 0;
    border: 0;
    padding: 7px 12px 1px 12px;

    span {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    // &:focus {
    //   border: medium none rgb(111, 255, 176);
    //   box-shadow: rgb(111, 255, 176) 0 0 2px 2px;
    //   outline: 0;
    // }

    &:hover {
      span:nth-of-type(1) {
        width: 23px;
      }

      span:nth-of-type(2) {
        width: 30px;
      }

      span:nth-of-type(3) {
        width: 20px;
      }
    }

    &.burgerButtonActive {       // ?????
      span:nth-of-type(1) {
        transform: rotate(45deg) translate(7px, 7px);
        width: 30px;
      }

      span:nth-of-type(2) {
        opacity: 0;
        pointer-events: none;
      }

      span:nth-of-type(3) {
        transform: rotate(-45deg) translate(7px, -7px);
        width: 30px;
      }
    }

    span{
      display: block;
      width: 30px;
      height: 3px;
      margin-bottom: 7px;
      background-color: $colorFontMain;
    }    
  }

  .contract_data{
    display: flex;
    flex-direction: row;
    min-height: 555px;

    .leftBlock, .hideLeftBlock{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-right: 1px solid $colorBorderMain;
      flex: 0 0 175px;
      margin: 12px 0;

      .menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0 13px;
        font-variant: small-caps;
        font-size: $fontSizeMain;
        line-height: 14px;
        color: $colorFontMain;
      
        .line{
          width: 44px;
          height: 1px;
          background-color: $colorBorderMain;
          margin: 8px;
        }
    
        .menu_item{
          width: 80%;
          padding: 12px;
          text-align: center;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: #fff;
    
          &:hover{
            font-size: $fontSizeMain + 1px;
          }

          &:last-child{
            white-space: nowrap
          }
        }    
    
        .active_menu_item{
          width: 80%;
          padding: 12px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid $colorBorderSecondary;
          filter: drop-shadow(0px 3px 3.5px rgba(139,139,139,0.52));
          background-color: #fff; 
          // line-height: 18px;
        }  
      }

      .delButton{
        text-align: center;
        background-color: $buttonColor;
        filter: drop-shadow(0px 3px 3.5px rgba(139,139,139,0.52));
        padding: 5px 0;
        margin: 8px;
        font-variant: small-caps;
        font-size: $fontSizeMain;
        line-height: 14px;
        color: $colorFontMain;
        cursor: pointer;
      }
    }
  }
}



// =============================================== Media queries ===============================================


@media only screen and (max-width: 1024px) {
  .contractContainer{
    .contract_data{  
      .leftBlock{
        flex: 0 0 158px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  
  .contractContainer{
    position: relative;
    .burgerButton{
      display: block;
    }
    
    .contract_data{
      // position: relative;

      .leftBlock{
        overflow: hidden;
        position: absolute;
        top: 35px;
        height: 330px;
        transition: height 1s;
        width: 100%;
        z-index: 1;
        margin-top: 0;
        align-items: flex-start;
        border-right: none;
        background-color: #e6e6e6;
        padding-bottom: 10px;

        .menu{
          margin: 0;
          align-items: flex-start;
        
          .line{
            display: none;
          }
      
          .menu_item{
            width: auto;
            padding: 20px 12px;
            background: none;
            font-size: 20px;
            font-weight: bolder;
            line-height: 18px;

            &:hover{
              font-size: 20px;
            }

            &:first-child{
              margin-top: 20px;
            }      
          }      
      
          .active_menu_item{
            width: auto;
            background: none;
            font-size: 20px;
            font-weight: bolder;
            padding: 20px 12px;
            border: none;
            color: gray;
            line-height: 18px;

            &:hover{
              font-size: 20px;
            }

            &:first-child{
              margin-top: 20px;
            }
          }  
        }
  
        .delButton{
          width: auto;
          padding: 8px 12px;
          background: none;
          font-size: 20px;
          // border: 1px solid $colorFontMain;
          color: #fff;
          background-color: $colorFontMain;
        }
      }

      .hideLeftBlock{
        overflow: hidden;
        position: absolute;
        top: 35px;
        height: 0;
        transition: height .2s;
        width: 100%;
        z-index: 1;
        margin-top: 0;
        align-items: flex-start;
        border-right: none;
        background-color: #e6e6e6;
        padding-bottom: 10px;
  
        .menu{
          margin: 0;
          align-items: flex-start;
        
          .line{
            display: none;
          }
      
          .menu_item{
            width: auto;
            padding: 20px 12px;
            background: none;
            font-size: 20px;
            font-weight: bolder;

            &:first-child{
              margin-top: 20px;
            }      
          }      
      
          .active_menu_item{
            width: auto;
            background: none;
            font-size: 20px;
            font-weight: bolder;
            padding: 20px 12px;
            border: none;
            color: gray;

            &:first-child{
              margin-top: 20px;
            }
          }  
        }
  
        .delButton{
          width: auto;
          padding: 8px 12px;
          background: none;
          font-size: 20px;
        }
      }
    }
  }

}

@media only screen and (max-width: 410px) {

  .contractContainer{
    .contract_data{
      .leftBlock{  
        .menu{      

          // .menu_item{
          //   &:last-child{
          //     display: none;
          //   }            
          // }

          .notShow{
            display: none;
          }

          // .active_menu_item{
          //   &:last-child{
          //     display: none;
          //   }            
          // }

          .notShow{
            display: none;
          }
          
        }
      }

      .hideLeftBlock{  
        .menu{      
          // .menu_item{
          //   &:last-child{
          //     display: none;
          //   }
          // }

            .notShow{
              display: none;
            }
          

          // .active_menu_item{
          //   &:last-child{
          //     display: none;
          //   }
          // }

          .notShow{
            display: none;
          }
        }
      }
    }    
  }
}