@import "../../../../_variables.scss";

.invoice{
  width: 100%;
  padding: 50px 12px 12px;
  color: $colorFontMain;
  font-size: $fontSizeMain;
  font-variant: small-caps;
  font-family: "open_sans_sb";

  .payment{
    border-bottom: 1px solid $colorBorderSecondary;
    padding-bottom: 30px;

    .title{
      text-align: center;
      font-size: $fontSizeMain + 2px;
      font-weight: 600;
      margin-bottom: 35px;
    }

    .contentPayment{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .serviceContainer{
        flex: 0 0 67%;

        .service{
          display: grid;
          grid-template-columns: 3fr 2.3fr;
          align-items: stretch;
          // justify-items: stretch;
          margin: 0 auto 15px;

          &:last-child{
            margin-bottom: 0;
          }
    
          .serviceName{        
            position: relative;
            
            div{
              // position: absolute;
              bottom: 0;
            }
          }

          .rightBlock{
            margin-left: 25%;

            .comment{
              font-family: "open_sans2";
              color: #575858;
              font-variant: normal;
              font-size: $fontSizeMain - 2px;
              line-height: 1.1;
            }
      
            .value{
              font-size: $fontSizeMain + 3px;
              font-weight: bold;
              line-height: 1.1;

              .ed{
                font-size: $fontSizeMain - 2px;
              }
            }
          }
        }
      }

      .buttonContainer{
        .button{
          display: inline-block;
          font-family: "open_sans2";
          font-size: $fontSizeMain - 2px;
          text-align: center;
          background-color: $buttonColor;
          filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));
          padding: 5px 5px;
          margin: 0 8px;
          // margin-left: auto;
          // margin-right: 0;

          cursor: pointer;
          float: right;
          text-decoration: none;
          color: $colorFontMain;
          width: 140px;
  
          &:first-child{
            // visibility: hidden;
            display: none;
          }
          &:last-child{
            // visibility: visible;
            // margin-top: 20px;
          }
        }
      }
    }
  }

  .bottomBlock{
    display: flex;
    padding-top: 30px;

    & > div{
      display: inline-block;
    }
    .subscriberData{
      flex: 0 0 45%;
      border-right: 1px solid $colorBorderSecondary;

      // & > div{
      //   margin-bottom: 10px;

        .field{
          flex: 0 0 30%;
          margin-right: 10px;
          margin-bottom: 10px;
          
          .fieldName{
            font-family: "open_sans2";
            font-variant: normal;
            font-size: $fontSizeMain - 4px;
            color: $secondaryFontColor;
            font-weight: bold;
          }
          .value{
            font-size: $fontSizeMain - 2px;
            display: inline-block;
          }
        }
      // }

      .subscriberDataTop{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .tariff{
      flex: 0 0 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding-left: 12px;

      .service1{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16px;
        font-size: $fontSizeMain - 2px;
        color: $colorFontMain;
        text-align: start;

        .serviceName{
          font-size: $fontSizeMain - 3px;
        }

        .serviceValue{
          font-size: $fontSizeMain - 3px;
        }
      }

      .service2{
        font-size: $fontSizeMain;
        line-height: $fontSizeMain;
        color: $colorFontMain;
        text-align: center;
        margin-top: 30px;

        .serviceName{
          font-size: $fontSizeMain;
          line-height: $fontSizeMain - 4;
          margin-bottom: 18px;
        }

        .serviceValue{
          font-size: $fontSizeMain - 3px;
        }
      }

      .index{
        vertical-align: super;
        font-size: $fontSizeMain - 6px;
      }
    }
  }
}


// =============================================== Media queries ===============================================

@media only screen and (max-width : 1440px)  {
  .invoice{  
    .payment{
      .contentPayment{  
        .serviceContainer{  
          .service{
            .rightBlock{
              margin-left: 15%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width : 1100px)  {
  .invoice{  
    .payment{  
      .contentPayment{
        flex-direction: column;

        .serviceContainer{
          display: inline-block;
          width: 80%;

          .service{
            // grid-template-columns: 2fr 3fr;
    
            .rightBlock{
              justify-self: start;
            }
          }
        }

        .buttonContainer{
          width: 80%;
          margin-top: 20px;
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap;
          justify-content: space-between;
          

          .button{
            margin-bottom: 20px;
            margin-left: 0;
            order: 2;

            &:last-child{
              margin-top: 0;
              order: 1;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .invoice{
    padding: 20px 12px 12px;

    .payment{
      padding-bottom: 10px;

      .contentPayment{
        .buttonContainer{
          .button{

            margin-left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width : 500px)  {
  .invoice{  
    .payment{  
      .contentPayment{
        .buttonContainer{
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    .bottomBlock{
      flex-direction: column;

      .subscriberData{
        border-right: none;
        border-bottom: 1px solid $colorBorderSecondary;
        padding-bottom: 10px;
      }
      .tariff{
        padding-top: 10px;
        & > div:first-child{
          margin-bottom: 20px;
        }
        .service1{
          width: 250px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (max-width : 360px)  {
  .invoice{  
    .payment{  
      .contentPayment{

        .serviceContainer{
          width: 90%;

          .service{
            .rightBlock{
              margin-left: 10%;
            }
          }
        }

        .buttonContainer{
          align-items: center;
        }
      }
    }
  }
}