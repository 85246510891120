$fontSizeMain: 16px;

// Светлый цвет шрифта
$colorFontLight: #fdfeff;
// Основной цвет шрифта
$colorFontMain: #033c5f;
// Второстепенный цвет шрифта
$secondaryFontColor: #c2c2c2;
// Цвет фона окна залогинивания
$phoneColor: #0a3558;


// Светлый цвет линий
$colorBorderLight: #486881;
//Основной цвет линий
$colorBorderMain: #005fa1;
// Второстепенный цвет линий
$colorBorderSecondary: #d5d5d5;


//Цвет фона кнопок
$buttonColor: #e6e6e6;
