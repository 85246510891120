@import "../../../_variables.scss";

.aside{
  flex: 1 1 25%;
  font-family: "open_sans_sb";
  font-variant: small-caps;
  color: $colorFontMain;
  margin-left: 15px;

  .aside_title{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    height: 80px;
  }

  .aside_data{

    .notice{
      background-color: #fff;
      filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));
      margin-bottom: 15px;
      padding: 15px 0;
      font-family: "open_sans2";
      font-variant: normal;
      border: 1px solid #e0e0e0;

      .warningStyle{
        border-left: 10px solid #ed1a39;
        padding: 15px;
      }

      .messageStyle{
        border-left: 10px solid #85c3ea;
        padding: 15px;
      }
      
      .titleNotice{
        font-size: $fontSizeMain + 2px;
        line-height: 1.1;
        text-align: center;
        margin-bottom: 15px;
      }

      .textNotice{
        font-size: $fontSizeMain - 2px;
        color: #575858;
        text-align: justify;
        // hyphens: auto;

        // -webkit-hyphens: auto;
        // -moz-hyphens: auto;
        // -ms-hyphens: auto;
      }
    }
  }
}


.congrats{
  // font-style: italic;
  // font-weight: bold;
  // font-size: $fontSizeMain + 2px;
  // margin-bottom: 20px;
  // color: orange;
}

.aside_data{
  // border: 2px solid $borderColor;
  // padding: 20px;
  // margin-bottom: 15px;
  // border-radius: 10px;
  // background-color: $bgColor;
  // box-shadow: 4px 4px 5px 5px $shadowColor;
  /* height: 85%; */
}

.textTitle{
  font-weight: bold;
  margin-bottom: 3px;
}

.aside_text{
  margin-bottom: 20px;
}

.empty{
  height: 20vh;
  text-align: center;
  margin-top: 10vh;
}


// =============================================== Media queries ===============================================


@media only screen and (max-width: 900px) {
  .aside{
  
    .aside_title{
      margin-bottom: 20px;
      // font-size: 24px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .aside{
  
    .aside_title{
      height: 130px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 708px) {
  .aside{
    margin-left: 0;
    // display: inline-block;
    // margin: 0 auto;
    align-self: stretch;
    .aside_title{
      height: 20px;
      // margin-bottom: 15px;
      // font-size: 24px;
    }
  }
}

