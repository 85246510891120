@import '../../../../variables';


.modalContainer{
  font-family: "open_sans2";
  font-size: $fontSizeMain;
  color: $colorFontMain;
  // font-weight: bold;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid $colorBorderLight;
  padding: 20px;
  box-sizing: border-box;
  font-variant: small-caps;



  .title{
    font-size: $fontSizeMain + 8px;
    text-align: center;
  }

  .line{
    height: 1px;
    width: 80%;
    background-color: #85c3e9;
    margin: 20px auto;
  }

  .message{
    display: inline-block;
    text-align: center;
  }

  
  .buttonContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;

    button{
      font-variant: small-caps;
      color: $colorFontMain;
      background-color: $buttonColor;
      box-shadow: 0px 3px 7px 0px rgba(139,139,139,0.51);
      padding: 5px 20px;
      border: none;    
    }
  } 

}
