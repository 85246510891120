@import "../../../../../variables";

.item_indication{
  width: 194px;
  height: 30px;
  font-family: "open_sans2";
  font-size: $fontSizeMain - 1px;
  color: $colorFontMain;
  font-weight: bold;
}

.item_indication[type="number"]{  //убирает стрелочки у числового инпута
  appearance: textfield;
}

.currentValueForm{
  margin-top: 20px;

  // font-family: "open_sans2";
  // .cub{
  //   vertical-align: super;
  //   font-size: 10px;
  // }

  // .prevDate{
  //   // font-family: "open_sans2";
  //   font-size: $fontSizeMain - 2px;
  //   color: $colorFontMain;
  //   margin-bottom: 20px;

  //   span{
  //     // font-weight: bold;
  //     // font-size: $fontSizeMain - 2px;
  //   }
  // }  
  // .currentValueSector{
  //   display: flex;
  //   justify-content: space-between;
  //   // font-family: "open_sans2";
  //   font-size: $fontSizeMain - 2px;
  //   align-items: flex-start;
  //   .button{    
  //     button{
  //       font-variant: small-caps;
  //       font-size: $fontSizeMain;
  //       color: $primaryTextColor;
  //       text-align: center;
  //       background-color: $buttonColor;
  //       // filter: drop-shadow(0px 3px 3.5px rgba(139,139,139,0.52));
  //       box-shadow: 0px 3px 7px 0px rgba(139,139,139,0.51);
  //       padding: 5px 25px;
  //       margin: 0 8px;
  //       border: none;    
  //     }
    
  //     img{
  //       width: 100%;
  //     }
  //   }
  // }

  // .sum{
  //   // font-family: "open_sans2";
  //   font-size: $fontSizeMain - 2px;
  //   color: $colorFontMain;
  //   margin-bottom: 30px;

  //   // & > div{
  //   //   display: inline-block;
  //   // }

  //   span{
  //     font-size: $fontSizeMain - 2px;
  //     // font-weight: bold;
  //   }
  // }



  // NewForm

  .newForm{
    display: grid;
    grid-template-columns: 2fr 1fr;
    // justify-items: center;

    .cub{
      vertical-align: super;
      font-size: 10px;
    }

    & > div{
      justify-self: center;
    }

    label{
      font-size: 13px;
    }

    input{
      width: 70px;
    }

    .value_m3{

    }

    .value_m3_red{
      padding: 6px 8px;
      border: 2px solid red;
      border-radius: 5px;
      box-shadow: none;
      background: none;
    }

    .space{
      height: 15px;
    }    

    .nowrap{
      white-space: nowrap;
    }

    .button{
      justify-self: center;
      margin-bottom: 10px;
      font-variant: small-caps;

      button{
        font-size: $fontSizeMain;
        color: $colorFontMain;
        text-align: center;
        background-color: $buttonColor;
        // filter: drop-shadow(0px 3px 3.5px rgba(139,139,139,0.52));
        box-shadow: 0px 3px 7px 0px rgba(139,139,139,0.51);
        padding: 5px 25px;
        margin: 0 8px;
        border: none;
      }
    }
  }
}


// =============================================== Media queries ===============================================
@media only screen and (max-width: 420px) {  
  .currentValueForm{
    
    .currentValueSector{
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }

    .newForm{
      grid-template-columns: 1fr;
      justify-items: center;

      label{
        width: 100%; // добавлено 19 марта
        text-align: center;  // добавлено 19 марта
      }

      .long{
        text-align: center;
      }
    }
  }
}