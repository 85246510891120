@import "../../variables";



.activateCont{
  text-align: center;
  margin: 0 auto;
  min-width: 260px;
  height: 420px;
  border: #2e5471 1px solid;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  font-family: "open_sans_sb";
  font-variant: small-caps;
  color: #fdfcfc;


  .activateMsg{
    position: relative;
    display: inline-block;
    top: 50%;
    transform: translate(0, -50%);

    .link{
      margin-top: 30px;
      a{
        color: #feffff;
        font-weight: normal;
        font-size: 18px;
      }
    }
  }
}

