@import "../../variables";

.main{
  display: flex;
  margin-left: 12px;
  justify-content: space-between;
  margin-top: 30px;

  .section{
    flex: 1 1 76%;

    .clickAddContract{
      margin-bottom: 20px;
      height: 80px;
      position: relative;  

      .addContractButton{
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: "open_sans2";
        background-color: #e6e6e6;
        filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));
        padding: 5px 10px;
        cursor: pointer;
        color: $colorFontMain;
        font-size: $fontSizeMain;
        font-variant: small-caps;
      }
    }

    .noDataCont{
      font-family: "open_sans_sb";
      color: $colorFontMain;
      font-size: $fontSizeMain;
      font-variant: small-caps;
      background-color: #fff;
      box-shadow: -24px 0 0 -12px #005fa1;
      filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));    
      min-width: 70%;
      min-height: 300px;
      text-align: center;
      margin-bottom: 30px;    
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

// .errorMsg{
//   color: red;
//   text-align: center;
// }

// =============================================== Media queries ===============================================
@media only screen and (max-width: 900px) {
  .main{

    margin-top: 15px;
    .section{
      .clickAddContract{
        margin-bottom: 20px;
      }
    }
  } 
}

@media only screen and (max-width: 768px) {
  .main{      
    .section{        
      .clickAddContract{
        height: 130px;
      }
    }
  }
}

@media only screen and (max-width: 708px) {
  .main{
    flex-direction: column;

    .section{        
      .clickAddContract{
        height: auto;
        position: static;

        .addContractButton{
          margin: 0 auto;
          width: 200px;
          position: static;
          transform: none;
          display: block;
        }
      }
    }
  } 
}