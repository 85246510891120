@import "../../variables";

.header {
  flex: 0 0 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px $colorBorderLight solid;
  padding: 10px 0;

  .logo{
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      .image{
        margin-right: 10px;
      }

      .text{
        color: $colorFontLight;
        font-size: 17px;        
        text-align: start;
        line-height: 24px;        

        div{
          font-weight: bold;
          font-size: 19px;   
        }
      }

      .textIn{
        color: #005fa1;
        font-size: 17px;        
        text-align: start;
        line-height: 24px;

        div{
          font-weight: bold;
          font-size: 19px;   
        }
      }
    }    
  }

  .userName{
    align-self: center;
    margin-right: 20px;
    color: $colorFontMain; 
    
    .exit{
      cursor: pointer; 
      margin-top: 40px;
      float: right;
    }
  }
}


.headerIn {
  flex: 0 0 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #85c3e9 solid;
  padding: 10px 0;

  .logo{
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      .image{
        margin-right: 10px;
      }

      .text{
        color: #005fa1;
        font-size: 17px;        
        text-align: start;
        line-height: 24px;

        div{
          font-weight: bold;
          font-size: 19px;   
        }
      }
    }    
  }

  .userName{
    align-self: center;
    margin-right: 20px;
    color: $colorFontMain; 
    
    .exit{
      cursor: pointer; 
      margin-top: 40px;
      float: right;
    }
  }
}

// =============================================== Media queries ===============================================


@media only screen and (max-width: 708px) {
  .header {
    flex: 0 0 80px;
    .logo{
      a{  
        .image{
          img{
            width: 60px;
          }
        }
  
        .text{
          font-size: 14px;  
  
          div{
            font-size: 16px;   
          }
        }
      }    
    }
    .userName{
      & > div{
        &:first-child{
          font-size: 14px;
        }
      }
      
      .exit{
        margin-top: 20px;
      }
    }
  }

  .headerIn {
    flex: 0 0 80px;
    .logo{
      a{  
        .image{          
          img{
            width: 60px;
          }
        }
  
        .text{
          font-size: 14px;  
  
          div{
            font-size: 16px;   
          }
        }
      }    
    }
    .userName{
      & > div{
        &:first-child{
          font-size: 14px;
        }
      }
      
      .exit{
        margin-top: 20px;
      }
    }
  }
}




@media only screen and (max-width: 550px) {
  .header {
    flex: 0 0 100px;
    flex-direction: column;
    justify-content: center;  

    .userName{
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 15px;
      
      .exit{
        margin-top: 0;
      }
    }
  }

  .headerIn {
    flex: 0 0 100px;
    flex-direction: column;
    justify-content: center;
  
    .userName{
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 15px;
      
      .exit{
        margin-top: 0;
      }
    }
  }
}

