@import "../../variables";

.footer{
  flex: 0 0 90px;
  border-top: 1px $colorBorderLight solid;
  text-align: center;
  margin-top: 20px;
  font-family: "open_sans_sb";
  font-size: 13px;
  font-variant: small-caps;
  color: $colorFontLight;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerIn{
  flex: 0 0 90px;
  border-top: 1px #85c3e9 solid;
  font-family: "open_sans_sb";
  font-size: 13px;
  font-variant: small-caps;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}