@import "../../../../variables";

.archiveComponent{
  margin: 0 auto;  
  padding: 12px;

  .oneService{
    display: inline-block;
    border-bottom: 1px solid $colorBorderSecondary;
    margin: 24px auto;
  
    &:last-child{
      border-bottom: none;
      padding-bottom: 0;
      margin-top: 5px;
    }
  }
  .viewArchive{
    display: flex;
    align-items: baseline;

    div{
      margin: 12px;
    }
  }

  .yearListComponent{
    div{
      display: flex; 
      align-items: baseline;
      div{
        margin-right: 20px;
      }
      div:last-child{
        margin-right: 0;
      }
    }
  }

  .activeYear, .passiveYear{
    cursor: pointer;
    font-weight: normal;
    color: #a5a5a5;
  } 

  .activeYear{
    color: $colorFontMain;
    font-size: $fontSizeMain + 4px;
    font-weight: bold;
  }

  .passiveYear{
    font-size: $fontSizeMain;
  }

  .oneYearTableMonth {
    // border-collapse: collapse;
    border-spacing: 0 8px;
    font-size: $fontSizeMain - 2px;
    height: 0;
    font-family: "open_sans2";
    color: $colorFontMain;
    font-variant: normal;
    // filter: drop-shadow(0px 3px 3.5px rgba(139, 139, 139, 0.52));
    filter: drop-shadow(2px 3px 2px rgba(139, 139, 139, 0.52));
    
    td, th {
      border-bottom: 1px solid rgb(200,200,200);  
    }

    th {
      background-color: #005fa1;
      color: #feffff;
      padding: 2px 8px;
      font-size: $fontSizeMain - 3px;
    }

    .tableHead{
      padding: 2px 30px;
    }

    .tdMonth {
      text-align: start;
    }

    td {     
      line-height: 1.1;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    // tr:nth-child(odd) td {
    //   background-color: rgb(250,250,250);
    // }

    // tr:nth-child(even) td {
    //   background-color: rgb(235,235,235);
    // }

    .row{
      padding-left: 30px;
    }
  }

}

// =============================================== Media queries ===============================================
@media only screen and (max-width: 486px) {
  .archiveComponent{
 
    .oneYearTableMonth {
      th {
        padding: 2px 6px;
        font-size: $fontSizeMain - 4px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .archiveComponent{
    display: none;
  }
}
