@import "variables";

 @font-face {
  font-family: "open_sans2";
  src: url(fonts/OpenSans-Regular.ttf);
  font-weight: normal;
  font-style: normal;
 }

 @font-face {
  font-family: "open_sans2";
  src: url(fonts/OpenSans-Bold.ttf);
  font-weight: bold;
  font-style: normal;
 }

 @font-face {
  font-family: "open_sans_sb";
  src: url(fonts/OpenSans-SemiBold.ttf);
  font-weight: normal;
  font-style: normal;
 }


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  // background: linear-gradient(to bottom, #afd8f8 0%, #ffffff 50px);
  // background-repeat: no-repeat, no-repeat;
  // background-color: #0a3558;
  font-family: "open_sans2";
  font-size: $fontSizeMain;  
}


