@import "../../../../variables";

.sendNumber_NoData{
  width: 100%;
  align-self: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: gray;
  font-style: italic;
}

.water_meterContainer{
  width: 100%;

  .water_meter{
    border-bottom: 1px solid $colorBorderSecondary;
    margin: 20px;
    padding-bottom: 10px;

    &:last-child{
      margin-bottom: 0;
    }

    .line{
      height: 1px;
      width: 250px;
      background-color: $colorBorderSecondary;
      margin: 0 auto;
    }

    &:last-child{
      border-bottom: 0;
    }
  }

  .meterData{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 14px;
    grid-column-gap: 10px;

    .title{
      font-family: "open_sans2";
      font-size: $fontSizeMain - 4px;
      font-variant: normal;
      font-weight: bold;
      color: $secondaryFontColor;
      margin-bottom: 5px;
    }

    .value{
      font-size: $fontSizeMain - 2px;
      line-height: 1.2;
      font-weight: 400;
      color: $colorFontMain;
    }
  }
}

// =============================================== Media queries ===============================================


@media only screen and (max-width: 533px) {  
  .water_meterContainer{  
    .meterData{
      grid-template-columns: 1fr 1fr;
    }
  }
}