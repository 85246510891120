@import '../../../../../variables';

  .trMonth td {
    // text-align: end;
    padding: 0 6px 0 10px;
  }

  .trMonth {
    .tdService {
      text-align: start;
      word-break: break-all;
    }
  }

  .tdMonthTotal {
    text-align: end;
    font-size: $fontSizeMain - 2px;
    padding: 0 2px 0 10px;
  }

  .trTotal td {
    padding: 2px 6px;
    background-color: #ededed;
    border-right: 1px solid #c8c8c8;
    font-family: "open_sans_sb";
    color: #033c5f;// #03639f;
  }


// =============================================== Media queries ===============================================
@media only screen and (max-width: 486px) {
  .trMonth td {
    padding: 0 4px 0 8px;
  }

  .trMonth {
    .tdService {
      font-size: $fontSizeMain - 4px;
      padding: 0 0px 0 8px;
    }
  }

  .tdMonthTotal {
    font-size: $fontSizeMain - 2px;
  }

  .trTotal td {
    padding: 2px 4px;
  }

}
